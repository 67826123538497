@tailwind base;
@tailwind components;
@tailwind utilities;
@import '~antd/dist/antd.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.query-filter .ant-select-selection-item {
  font-weight: 900;
}
.ant-select-arrow {
  color: white;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #FE4672;
  border-color: #FE4672;
}
.ant-pagination-item a {
  color: white;
}
.ant-pagination-item-active {
  background-color: #000;
}
.data-workarea {
  height: calc(100vh - 230px);
  width: calc(100vw - 100px);
}
.new-item-block .ant-select-arrow {
  color: #000;
}
.search-filter .ant-select-selection-item-content {
  color: black;
}